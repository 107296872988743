import {ReactElement, useEffect, useState} from 'react'
import Layout from "@/components/Layout";
import {useApi} from "@/hoc/Api/context";
import {useNavigate, useSearchParams} from "react-router-dom";
import Login from "@/components/Login";
import Signup from "@/components/Signup";
import ChangePasswordModal from "@/components/ChangePasswordModal";
import {Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import {useSeo} from "@/hoc/Seo/context";
import {SeoPage} from "@/hoc/Seo/provider";
import Loader from "@/components/Loader";
import {ErrorCodes} from "@/models/ErrorCodes";

const Auth = ({...props}): ReactElement => {
    const api = useApi()
    const seo = useSeo()
    let [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const [showPasswordRecoveryModal, setShowPasswordRecoveryModal] = useState<boolean>(searchParams.get("mode") === "recovery")
    const [loginSelected, setLoginSelected] = useState<boolean>(true);
    if (api.IsLogged() != null) {
        navigate(searchParams.get("from") ?? "/Profilo")
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        seo.setSeo(SeoPage.auth)
        if (searchParams.get("mode") === "confirm") {
            api.ConfirmUser(searchParams.get("token") ?? "").then(() => {
                toast("Utente confermato con successo", {type: "success"})
                navigate("/Auth")
            })
        }
        let errorCode = searchParams.get("errorCode")
        if (errorCode) {
            let errorText = '';
            switch (errorCode) {
                case ErrorCodes.LOGIN_NEEDED:
                    errorText = "E' necessario effettuare il login per procedere all'acquisto"
            }
            setTimeout(() => {
                toast.error(errorText)
            }, 0)
        }
    }, [])

    return (
        <Layout>
            <main className="signup-in__container container">
                <div className="row justify-content-around">

                    <section className="signup-in signup-in__section col-12 col-sm-5 col-md-6 col-lg-4 col-xl-4 ">
                        <h2 className="signup-in__title text--primary-color">Accedi</h2>
                        <p className="signup-in__description text--heading-color">Accedi per acquistare o vendere i biglietti della Sampdoria</p>
                        {
                            loginSelected ?
                                (
                                    <>
                                        <Login from={searchParams.get("from") ?? undefined}/>
                                    </>
                                ) : (
                                    <>
                                        <button id="signin-button" className="btn btn--outlined"
                                                onClick={() => setLoginSelected(true)}>
                                            <span className="btn__text-gradient">Accedi Subito</span>
                                        </button>
                                    </>
                                )
                        }
                    </section>

                    <section className="signup-in d-none d-xl-flex col-lg-2 col-xl-2"></section>

                    <section className="signup-in signup-in__section col-12 col-sm-5 col-md-6 col-lg-6 col-xl-4">
                        <h2 className="signup-in__title text--primary-color">Registrati</h2>
                        <p className={loginSelected ? "signup-in__description" : "signup-in__description__small"}>Registrati per acquistare o vendere i biglietti della Sampdoria</p>
                        {
                            loginSelected ?
                                (
                                    <>
                                        <button id="signup-button"
                                                className="btn btn--outlined "
                                                onClick={() => setLoginSelected(false)}>
                                            <span className="btn__text-gradient">Crea il tuo account</span>
                                        </button>
                                    </>
                                ) :
                                (
                                    <>
                                        <Signup onSubmit={() => {
                                            setLoginSelected(true)
                                            window.scrollTo(0, 0)
                                        }}/>
                                    </>
                                )
                        }
                    </section>

                    <section className="signup-in d-none d-xl-flex col-xl-2">
                        {
                            <Modal show={showPasswordRecoveryModal}
                                   onHide={() => {
                                       setShowPasswordRecoveryModal(false)
                                   }}
                                   contentClassName={"account-change-data-modal__content checkout-modal__content ticket-cards__modal__content"}
                                   dialogClassName={"checkout-modal ticket-cards__modal overflow-md-hidden"}
                            >
                                <ChangePasswordModal onClose={() => {
                                    setShowPasswordRecoveryModal(false)
                                }}
                                                     token={searchParams.get("token") ?? undefined}
                                />
                            </Modal>
                        }
                    </section>
                </div>
            </main>
        </Layout>

    )
}

export default Auth
